<template>
  <div class="page-list-content admin_container_default">
    <div class="page-list-table cpx-4">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            作成済み
            <br />
            コンテンツ一覧
          </div>
        </div>
        <div class="row-input admin_center">
          <input
            type="text"
            class="form-control w-100"
            placeholder="検索"
            v-model="keywords"
            @keydown.enter.prevent="searchList()"
            :disabled="disableSearch"
          />
          <div class="w-100 d-flex pt-3">
            <div class="fd-admin-listproperty-card-filter filter-date w-50">
              <date-picker
                name="date"
                v-model="start_date"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="searchList()"
              ></date-picker>
            </div>
            <div class="my-auto px-2">
              <span class="text-center" style="font-size: 24px">~</span>
            </div>
            <div class="fd-admin-listproperty-card-filter filter-date w-50">
              <date-picker
                name="date"
                v-model="end_date"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="searchList()"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="createPage()" class="button-create-page">
            新規作成
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables
        :items="listPage.data"
        :fields="fields"
        :itemsPerPage="50"
        :columSort="{ column: 'updated_at', asc: false }"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:page_title="{ item }">
          <td style="min-width: 145px; width: 30%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.page_title }}
            </div>
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td style="min-width: 170px; width: 40%" class="py-2">
            <!-- {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }} -->
            {{
              item.updated_at &&
              moment(new Date(item.updated_at)).format("YYYY/MM/DD HH:mm:ss")
            }}
          </td>
        </template>
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <CButton
              class="mx-1 text-nowrap btn-other bg-0076ba"
              color="primary"
              square
              v-on:click="showDetailPage(item.id)"
              size="sm"
              v-b-modal.modal-detail-page
            >
              確認
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-other"
              square
              v-on:click="editPage(item.id)"
              size="sm"
              v-b-modal.modal-comfirm
            >
              編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-del"
              square
              v-on:click="showConfirm(item.id)"
              size="sm"
              v-b-modal.delete-modal-page
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listPage.total"
      >
        <p class="font-weight-bold">
          {{ listPage.total }}件中{{ listPage.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listPage.last_page"
      >
        <CPagination
          v-if="listPage.total"
          :activePage.sync="page"
          :pages="listPage.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removePage(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="modal-detail-page" hide-footer size="xl">
        <div
          class="row p-3"
          style="overflow-y: auto; max-height: 600px"
          v-if="detailPage"
        >
          <div class="col-12 page-title">
            {{ detailPage.page_title }}
          </div>
          <div class="col-12 py-4 accordion accordion-page">
            <div
              class="w-100"
              v-for="(itemBody, indexBody) in page_Content.blocks"
              :key="indexBody"
              :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
            >
              <!-- {{ itemBody.id }}_{{ itemBody.idChild }} -->
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'paragraph'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :style="{
                  lineHeight:
                    itemBody.data.lineHeight &&
                    itemBody.data.lineHeight !== null
                      ? itemBody.data.lineHeight
                      : '',
                }"
              >
                <div
                  v-if="itemBody.data.text"
                  v-html="itemBody.data.text"
                ></div>
                <div
                  v-else
                  v-html="itemBody.data.text"
                  style="height: 1.6em"
                ></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'tableOfContents'"
              >
                <h3>目次</h3>
                <ul class="list-unstyled pl-4 listTableOfContent">
                  <li
                    v-for="(itemTOC, index) in itemBody.data.items"
                    :key="index"
                    class="pl-3"
                  >
                    <a
                      style="cursor: pointer"
                      v-scroll-to="{ el: '#' + itemTOC.href, offset: -20 }"
                      v-html="itemTOC.text"
                    ></a>
                  </li>
                </ul>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'header'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <h1
                  v-if="itemBody.data.level === 1"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h1>
                <h2
                  v-if="itemBody.data.level === 2"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h2>
                <h3
                  v-if="itemBody.data.level === 3"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h3>
                <h4
                  v-if="itemBody.data.level === 4"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h4>
                <h5
                  v-if="itemBody.data.level === 5"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h5>
                <h6
                  v-if="itemBody.data.level === 6"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h6>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'list'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <ul class="w-100" style="list-style-type: decimal">
                  <li
                    v-for="(itemList, indexList) in itemBody.data.items"
                    :key="indexList"
                    class="mb-2"
                    v-html="itemList"
                  ></li>
                </ul>
              </div>
              <div
                class="item-data w-100"
                v-if="itemBody.type === 'toggle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div
                  class="w-100 d-flex align-items-center toggle-block__selector"
                  :id="itemBody.id"
                  :class="
                    itemBody.data.status === 'open'
                      ? 'toggle-open'
                      : 'toggle-close'
                  "
                >
                  <span
                    class="icon-toggle-panel mr-2"
                    @click="toggleBlock(itemBody)"
                  >
                    <b-icon
                      :icon="
                        itemBody.data.status === 'open' ? 'x-lg' : 'plus-lg'
                      "
                    ></b-icon>
                  </span>
                  {{ itemBody.data.text }}
                </div>
              </div>
              <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'code'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div v-html="itemBody.data.code"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100 my-3"
                v-if="itemBody.type === 'raw'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : ''
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div v-html="itemBody.data.html"></div>
                <!-- <code>{{ itemBody.data.html }}</code> -->
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'table'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        v-for="(itemTblTh, indexTblTh) in itemBody.data
                          .content[0]"
                        :key="indexTblTh"
                        class="border-bottom-0"
                      >
                        <span class="w-100" v-html="itemTblTh"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(itemTblTr, indexTblTr) in itemBody.data
                        .contentTbody"
                      :key="indexTblTr"
                    >
                      <td
                        v-for="(itemTblTd, indexTblTd) in itemTblTr"
                        :key="indexTblTd"
                      >
                        <span class="w-100" v-html="itemTblTd"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'checklist'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div
                  class="custom-control custom-checkbox"
                  v-for="(itemCheckbox, indexCheckbox) in itemBody.data.items"
                  :key="indexCheckbox"
                >
                  <input
                    :id="'checkbox-' + itemBody.id + indexCheckbox"
                    type="checkbox"
                    :name="'checkbox-' + itemBody.id + indexCheckbox"
                    class="custom-control-input"
                    :checked="itemCheckbox.checked"
                  />
                  <label
                    :for="'checkbox-' + itemBody.id + indexCheckbox"
                    class="custom-control-label ml-1"
                  >
                    <span v-html="itemCheckbox.text"></span>
                  </label>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'warning'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div class="w-100 bg-warning p-3 text-white">
                  <div class="w-100 font-weight-bold">
                    {{ itemBody.data.title }}
                  </div>
                  <hr />
                  <div class="w-100">
                    {{ itemBody.data.message }}
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'quote'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <blockquote
                  class="otro-blockquote"
                  :class="
                    itemBody.data.alignment === 'left'
                      ? 'text-left'
                      : 'text-right'
                  "
                >
                  <span>
                    {{ itemBody.data.caption }}
                  </span>
                  <span v-html="itemBody.data.text"></span>
                </blockquote>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'headerStyle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
              >
                <div class="titleStyle" :class="itemBody.data.class">
                  <h1 class="dataInput">
                    {{ itemBody.data.text }}
                  </h1>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'boxStylle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
              >
                <div class="boxStyle" :class="itemBody.data.class">
                  <span
                    class="box-title px-2 border-0"
                    v-if="itemBody.data.title"
                    >{{ itemBody.data.title }}</span
                  >
                  <div
                    class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                    v-html="itemBody.data.text"
                  ></div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'delimiter'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div class="w-100 text-center ce-delimiter"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'image'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <a
                  v-if="replaceText(itemBody.data.linkImage)"
                  :href="itemBody.data.linkImage"
                >
                  <img :src="itemBody.data.file.url" alt="" width="auto" />
                </a>
                <img v-else :src="itemBody.data.file.url" alt="" width="auto" />
                <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'attachesPDF'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <!-- <ViewPdf
                  v-bind:pageNumber="lastPagePDF"
                  v-bind:timeWatching="timeWatchingEmbed"
                  v-bind:idLast="idLastPDF"
                  v-bind:src="itemBody.data.file.url"
                  v-bind:idComponent="itemBody.id"
                /> -->
                <b-button
                  variant="primary"
                  class="btn-preview-fixed mr-0"
                  name="btn-view-PDF"
                  v-on:click.prevent="viewFilePDF(itemBody.data.file)"
                  v-b-modal.modal-detail-pdf
                >
                  {{ itemBody.data.title }}
                </b-button>
              </div>
              <!-- <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <iframe
                    id="main-slide"
                    :src="
                      'https://azu.github.io/slide-pdf.js/?slide=' +
                      itemBody.data.file.url
                    "
                    scrolling="no"
                    allowtransparency="true"
                    width="100%"
                    height="500px"
                    style="border: 0"
                  >
                  </iframe>
                </div> -->
              <div
                :id="itemBody.id"
                class="item-data w-100"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                v-if="
                  itemBody.type === 'embed' &&
                  itemBody.data.service === 'youtube'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <YoutubePlayer
                  v-bind:idElemYoutube="itemBody.id"
                  v-bind:idLastYoutube="idLastEmbed"
                  v-bind:timeWatching="timeWatchingEmbed"
                  v-bind:src="itemBody.data.source"
                  v-bind:heightPlayer="itemBody.data.height"
                  v-bind:widthPlayer="itemBody.data.width"
                  v-bind:dataVideo="itemBody.data"
                />
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                v-if="
                  itemBody.type === 'embed' && itemBody.data.service === 'vimeo'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <VimeoPlayer
                  v-bind:idElemVimeo="itemBody.id"
                  v-bind:idLastVimeo="idLastEmbed"
                  v-bind:timeWatching="timeWatchingEmbed"
                  v-bind:src="itemBody.data.embed"
                  v-bind:heightPlayer="itemBody.data.height"
                  v-bind:widthPlayer="itemBody.data.width"
                  v-bind:dataVideo="itemBody.data"
                />
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'anyButton'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <!-- <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    target="_blank"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a> -->
                <a
                  v-if="itemBody.data.type === null"
                  class="anyButton"
                  :class="itemBody.data.style"
                  @click="downloadFile($event, itemBody.data)"
                >
                  {{ itemBody.data.text }}
                </a>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'roundButton' ||
                      itemBody.data.style === 'socialGlossySmooth' ||
                      itemBody.data.style === 'socialSquare')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <a
                    v-if="itemBody.data.twitter !== null"
                    :href="itemBody.data.twitter"
                    class="btn-social-circle btn-social-circle--twitter"
                    ><i class="fab fa-twitter"></i>
                  </a>
                  <a
                    v-if="itemBody.data.facebook !== null"
                    :href="itemBody.data.facebook"
                    class="btn-social-circle btn-social-circle--facebook"
                    ><i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    v-if="itemBody.data.pocket !== null"
                    :href="itemBody.data.pocket"
                    class="btn-social-circle btn-social-circle--pocket"
                    ><i class="fab fa-get-pocket"></i>
                  </a>
                  <a
                    v-if="itemBody.data.feedly !== null"
                    :href="itemBody.data.feedly"
                    class="btn-social-circle btn-social-circle--feedly"
                    ><i class="fas fa-rss"></i
                  ></a>
                </div>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialGiza' ||
                      itemBody.data.style === 'socialSmartPhone')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div
                    :class="
                      itemBody.data.style === 'socialGiza'
                        ? 'btn-social-giza'
                        : 'btn-social-phone'
                    "
                  >
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-twitter'
                          : 'btn-social-phone-twitter ml-0 mr-3'
                      "
                      v-if="itemBody.data.twitter !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="3535"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-facebook'
                          : 'btn-social-phone-facebook ml-0 mr-3'
                      "
                      v-if="itemBody.data.facebook !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="5467"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-pocket'
                          : 'btn-social-phone-pocket ml-0 mr-3'
                      "
                      v-if="itemBody.data.pocket !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-get-pocket fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-feedly'
                          : 'btn-social-phone-feedly ml-0 mr-3'
                      "
                      v-if="itemBody.data.feedly !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fas fa-rss fa-stack-1x"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialFlat' ||
                      itemBody.data.style === 'socialIsometric')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                        "
                      >
                        <i class="fab fa-twitter"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                        "
                      >
                        <i class="fab fa-facebook"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                        "
                      >
                        <i class="fab fa-get-pocket"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                        "
                      >
                        <i class="fas fa-rss"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100 my-3"
                v-if="itemBody.type === 'attachesFile'"
              >
                <div class="w-100 d-flex box-file-upload">
                  <div
                    class="iconFile"
                    :data-extension="itemBody.data.file.extension"
                    :style="{ color: itemBody.data.file.color }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="40"
                    >
                      <path
                        d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                      />
                    </svg>
                  </div>
                  <div class="fileName">
                    <div class="w-100">{{ itemBody.data.file.name }}</div>
                    <div
                      class="text-muted w-100"
                      v-html="convertSize(itemBody.data.file.size)"
                    ></div>
                  </div>
                  <div class="iconDowload ml-auto">
                    <a
                      class="iconDowload"
                      v-bind:download="itemBody.data.file.url"
                      rel="nofollow noindex noreferrer"
                      @click="downloadFileUpload($event, itemBody.data.file)"
                    >
                      <i class="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removePage(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div> -->
      </b-modal>
    </div>
    <b-modal id="modal-detail-pdf" hide-footer modal-class="modal-fullscreen">
      <ViewPdf
        v-bind:src="filePDFView && filePDFView.url"
        v-bind:idComponent="filePDFView && filePDFView.idComponent"
        v-bind:dataInfo="filePDFView"
      />
    </b-modal>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import ViewPdf from "../../components/viewPdf/index.vue";
// import ViewPdf from "../../components/viewPdf/pdfView.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListPage",
  components: {
    Tables,
    ViewPdf,
    YoutubePlayer,
    VimeoPlayer,
  },
  data() {
    return {
      fields: tableFields.PAGE,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataPage: {
        id: null,
        shop_id: null,
      },
      limit: 50,
      page: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      keywords: "",
      disableSearch: false,
      start_date: "",
      end_date: "",
      optionsDate: {
        format: "YYYY-MM-DD HH:mm:ss",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      detailPage: {
        page_title: null,
        page_content: [
          {
            blocks: null,
          },
        ],
      },
      page_Content: [],
      lastPagePDF: null,
      timeWatchingEmbed: 0,
      idLastPDF: null,
      filePDFView: null,
    };
  },
  created() {
    this.getListPages(1);
  },
  computed: {
    ...mapGetters(["listPage", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListPages(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listPage() {
      this.page = this.listPage.current_page;
      this.disableSearch = false;
    },
    page() {
      this.getListPages(this.page);
    },
  },
  methods: {
    ...mapActions({ getListPage: "getListPage" }),
    ...mapActions({ deletePage: "deletePage" }),
    ...mapActions({ getPageById: "getPageById" }),
    moment,
    createPage() {
      this.$router.push({
        name: this.$route.params.shopId ? "create page" : "create page domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    getListPages(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      this.getListPage(formData);
    },
    async removePage(id) {
      this.dataPage.id = id;
      this.dataPage.shop_id = this.shop_id;
      const dataReturn = await this.$store.dispatch(
        "deletePage",
        this.dataPage
      );
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-page");
          this.getListPages(this.page);
        });
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    editPage(id) {
      this.$router.push({
        name: this.$route.params.shopId ? "edit page" : "edit page domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListPages(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    searchList() {
      this.disableSearch = true;
      const formData = {
        page: this.page,
        data: {
          shop_id: this.shop_id,
          keywords: this.keywords,
          start_date: this.start_date,
          end_date: this.end_date,
          limit: this.limit,
        },
      };
      this.getListPage(formData);
    },
    async showDetailPage(id) {
      this.detailPage = null;
      const dataRequest = {
        id: id,
        shop_id: localStorage.getItem(Constants.SHOP_ID),
      };
      const dataRepond = await this.getPageById(dataRequest);
      if (dataRepond.success === true) {
        this.detailPage = dataRepond.data;
        this.page_Content = dataRepond.data.page_content;
        this.page_Content.blocks.map((item) => {
          if (item.type === "table") {
            item.data.contentTbody = item.data.content.filter(
              (item, index) => index > 0
            );
            return item;
          }
          //
          var blocksData = this.page_Content.blocks;
          if (item.type === "toggle") {
            item.data.status = "closed";
            const indexBlockToggle = this.page_Content.blocks.indexOf(item);
            if (item.data.child.length >= item.data.items) return;
            for (let i = 1; i <= item.data.items; i++) {
              console.log(i);
              console.log(blocksData[indexBlockToggle + i]);
              item.data.child.push(blocksData[indexBlockToggle + i]);
              if (item.data.status === "closed")
                blocksData[indexBlockToggle + i].showDisplay = true;
              blocksData[indexBlockToggle + i].idChild = item.id;
            }
          }
        });
      }
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    checkIndex(toggle) {
      var listToggle = this.page_Content.blocks.filter(
        (item) => item.idChild === toggle.idChild
      );
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },
    async viewFilePDF(file) {
      this.$store.commit("set", ["clickOnPDF", true]);
      this.filePDFView = file;
    },
    async downloadFile(event, data) {
      const fileType = [
        "doc",
        "docx",
        "odt",
        "pdf",
        "rtf",
        "tex",
        "txt",
        "pptx",
        "ppt",
        "mp3",
        "mp4",
        "xls",
        "html",
        "htm",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "zip",
        "rar",
        "exe",
        "svg",
        "key",
        "sketch",
        "ai",
        "psd",
        "dmg",
        "json",
        "csv",
      ];
      const url = data.link;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      const typeFile = url.substring(url.lastIndexOf(".") + 1);
      const checkFile = fileType.filter((item) => item === typeFile);
      if (checkFile.length > 0) {
        fetch(url, {
          header: "Access-Control-Allow-Origin: *",
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Use the blob here...
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
          });
      } else {
        if (data.checkbox) {
          window.open(url, "_blank");
          return;
        }
        window.location = url;
      }
    },
    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion-page .toggle-block__item {
  margin: unset;
  max-width: unset;
}
.accordion-page img {
  max-width: 100% !important;
}
.page-title {
  font-family: $fontFamilyBase;
}
.accordion-page {
  font-size: $fontSizeBase;
  font-family: $fontFamilyBase;
  line-height: $lineHeightBase;
}
</style>
